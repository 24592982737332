import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import Content from "../components/Content";
import { DeleteCustomer, getCustomers } from "../features/customers/CustomerService";
import Loader from "../components/Loader/Loader";
import moment from "moment";
import { PiDotsThreeCircleVerticalFill, PiDotsThreeVertical } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import CustomerEdit from "./CustomerEdit";
import DeleteModel from "../components/DeleteModel";
import TableContainer from "../components/TableContainer/TableContainer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Pagination from "../components/Pagination/Pagination";
import UserAvatar from "../components/UserAvatar/UserAvatar";
import { paginateData } from "../utils/utils";
// import DeleteModal from "../components/CustomModal";

const Customers = () => {
  const [custData, setCustData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profID, setProfID] = useState(null);
  const [info, setInfo] = useState("")
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])

  const [noData, setNoData] = useState(false)




  // console.log(modalData)
  const navigate = useNavigate()
  const fetchData = async () => {
    setIsLoading(true);
    const response = await getCustomers();
    console.log(response);
    setAllData(response);
    setAllData2(response);
    let slicedData = paginateData(page, rowsPerPage, response)
    setData(slicedData)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);



  async function deleteCustomer() {

    // let payload ={
    //   created_by:userName,
    // }
    try {
      await DeleteCustomer(deleteId);
      // toast.success("Specification Deleted Successfully!");


    } catch (err) {
      console.error(err);
    }
    setOpenModal1(false)
    fetchData()
  }
  const data1 = custData?.map((item, index) => {

    let verification = "";
    if (item?.is_verified) {
      verification = "Approved";
    } else {
      verification = "Pending";
    }

    let isBlock = "";
    if (item?.isBlocked) {
      isBlock = "Active";
    } else {
      isBlock = "Inactive";
    }

    const address = [item?.city, item?.state, item?.country].join(",");

    return {
      firstname: item?.firstname,
      mobile: item?.mobile,
      // is_verified: verification,
      fileUrl: item?.profile?.fileUrl,
      fileName: item?.profile?.fileName,
      date_of_birth: item?.date_of_birth,
      isBlocked: isBlock,
      address: address,
      country: item?.country,
      state: item?.state,
      city: item?.city,
      _id: item._id,
    };
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "firstname",
      sorter: (a, b) => a?.firstname?.length - b?.firstname?.length,
      render: (fileName, res) => {
        console.log(fileName, res)
        return (
          <div className="d-flex align-items-center gap-2">
            <img
              src={res?.fileUrl}
              alt=""
              style={{ width: "50px", height: "50px", objectFit: "contain", borderRadius: '100%' }}
            />
            <div>{fileName}</div>
          </div>
        )
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    // {
    //   title: "Profile",
    //   dataIndex: "fileUrl",

    // },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'date_of_birth',
      render: (date) => moment(date).format('DD/MM/YYYY'), // Format the date
    },
    {
      title: 'Action',
      dataIndex: '',
      render: (text, item) => {
        const menu = (
          <Menu>
            <Menu.Item key="1"
              onClick={() => {
                setOpenModal(true);
                setInfo(item)

              }} >Edit</Menu.Item>
            <Menu.Item key="2" onClick={() => {
              setDeleteId(item._id); // Set the ID for deletion
              setOpenModal1(true); // Open the delete confirmation modal
            }}>Delete</Menu.Item>

          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <PiDotsThreeVertical style={{ fontSize: '24px', cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];
  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <div>
        <h2 className="mb-4">Customers</h2>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '2%' }}></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Date Of Birth</TableCell>
                <TableCell>Address</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell><UserAvatar name={res?.firstname} img={res?.profile?.fileUrl} /></TableCell>
                    <TableCell className='text-capitalize'>{res?.firstname}</TableCell>
                    <TableCell className='text-capitalize'>{res?.mobile}</TableCell>
                    <TableCell className='text-capitalize'>{moment(res?.date_of_birth).format('DD-MM-YYYY') !== 'Invalid date'?moment(res?.date_of_birth).format('DD-MM-YYYY'):'--'}</TableCell>
                    <TableCell className='text-capitalize'>{res?.city}, {res?.state}, {res?.country}</TableCell>
                    <TableCell className='text-capitalize'></TableCell>

                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
        {/* <Table columns={columns} dataSource={data} /> */}
        <CustomerEdit
          open={openModal}
          onClose={() => setOpenModal(false)}
          info={info}
          fetchData={fetchData}
        />
        <DeleteModel
          open={openModal1}
          title={`Are you sure you want to delete this Customer?`}
          hideModal={() => setOpenModal1(false)}
          performAction={deleteCustomer} // Pass the delete function
        />
      </div>
    </Content>
  );
};

export default Customers;
