import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Content from "../components/Content";
import { getAppointments } from "../features/appointments/AppointmentService";
import moment from "moment";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import AppointmentModal from "./AppointmentModal";
import { Dropdown, Menu } from 'antd';
import { PiDotsThreeVertical } from "react-icons/pi";
import CustomModal from "../components/CustomModal";
import { toast } from "react-toastify";




const Appointment = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };



  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Doctor Name",
      dataIndex: "docname",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Customer Name",
      dataIndex: "custname",
      sorter: (a, b) => a.name?.length - b?.name?.length,
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span style={{
          color: text === "Upcoming" ? "green"
            : text === "Completed" ? "blue" : text === "Cancelled" ? "red"
              : text === "Ongoing" ? "orange" : "brown"
        }}>
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, item) => {
        const menu = (
          <Menu>
            <Menu.Item key="1"
              onClick={() => {
                if (item?.status === "Upcoming" || item?.status === "Cancelled" || item?.status === "Requested") {
                  setOpenModal(true);
                  setInfo(item)
                }
                else if (item.status === "Ongoing") {
                  toast.error("Sorry Appointment is Ongoing!");
                }
                else if (item.status === "Completed") {
                  toast.error("Sorry Appointment is Completed!")
                }

              }} >Edit</Menu.Item>
            <Menu.Item key="2" onClick={() => showModal()}>Delete</Menu.Item>
            <Menu.Item key="3" onClick={() => navigate("/admin/appointmentView", { state: { appointment: item?.appointment } })}>View</Menu.Item>
          </Menu >
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <PiDotsThreeVertical style={{ fontSize: '24px', cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];


  const [appointmentData, setAppoinmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState("")

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAppointments();
      console.log(response, "get all the appontes---")
      setAppoinmentData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = appointmentData?.map((item, index) => {
    const date = moment(item?.appointmentDateTime).utc().format("DD/MM/YYYY");
    const time = moment(item?.appointmentDateTime).utc().format("hh:mm A");

    let value = "";

    if (item?.status === "pending" || item?.status === "re schedule") {
      value = "Requested"
    }
    else if (item?.status === "confirmed") {
      value = "Upcoming"
    }
    else if (item?.status === "completed") {
      value = "Completed"
    }
    else if (item?.status === "professional cancel" || item.status === "user cancel") {
      value = "Cancelled"
    }
    else if (item.status === "started") {
      value = "Ongoing"
    }

    return {
      key: index + 1,
      docname: item?.Professional?.firstname,
      custname: item?.user?.firstname,
      time: time,
      date: date,
      location: item?.location,
      status: value,
      action: item,
      appointment: item,
    };
  });

  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <div>
        <h4 className="mb-4 title">Appointments</h4>
        <div>
          <Table columns={columns} dataSource={data} />
          <AppointmentModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            info={info}
            fetchData={fetchData}
          />
          <CustomModal
            hideModal={hideModal}
            open={open}
            // performAction={() => handleManufacturer(cID)}
            title="Are you sure you want to delete this Appointment?"
          />
        </div>
      </div>
    </Content>
  );
};

export default Appointment;
