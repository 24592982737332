import React, { useEffect, useState } from 'react'
import { Modal } from "antd";
import moment from 'moment';
import { slotByProfessionalId, updateAppointmentById } from '../features/appointments/AppointmentService';
import { toast } from 'react-toastify';
import Loader from '../components/Loader/Loader';

const AppointmentModal = ({ open, onClose, info, fetchData }) => {
    useEffect(() => {
        if (info?.date) {
            const formattedDate = moment(info?.date, "DD/MM/YYYY").format("YYYY-MM-DD");
            setDate(formattedDate);
            setTime(info?.time)
        } else {
            setDate("");
        }
    }, [info]);
    console.log(info)

    const userToken = JSON.parse(localStorage.getItem("user"));
    console.log(userToken?.token, "token----")


    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [selectedTime, setSelectedTime] = useState('');
    const [slots, setSlots] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    function convertToISOFormat(dateString, timeString) {
        let [year, month, day] = dateString.split("-");
        let [time, modifier] = timeString.split(" ");
        let [hours, minutes] = time.split(":");
        if (modifier === "PM" && hours !== "12") {
            hours = parseInt(hours, 10) + 12;
        } else if (modifier === "AM" && hours === "12") {
            hours = "00";
        }
        let isoString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;

        return isoString;
    }



    console.log(date, "datae------");
    console.log(selectedTime, "time-----");

    const handleReschdule = async (e) => {
        e.preventDefault();
        const utcDateTime = convertToISOFormat(date, selectedTime);
        console.log(utcDateTime)

        try {

            const paylaod = {
                appointmentDateTime: utcDateTime,
                id: info?.appointment?._id,
                professionalId: info?.appointment?.Professional?._id
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken?.token}`
                }
            };
            const response = await updateAppointmentById(paylaod, config);
            console.log(response, "updated--");
            setDate("");
            setTime("");
            onClose();
            toast.success("Your Appointment has been Rescheduled!");
            fetchData();
        } catch (error) {
            console.log(error)
        }

    }



    useEffect(() => {
        const fetchData = async () => {
            try {

                setIsLoading(true)
                const paylaod = {
                    professionalId: info?.appointment?.Professional?._id,
                    date: date
                }
                const response = await slotByProfessionalId(paylaod);
                setSlots(response);
                setIsLoading(false)
                console.log(response, "slots available---");
            } catch (error) {
                setIsLoading(false);
                console.log(error)
            }
        }

        fetchData();

    }, [date])


    return (
        <Modal
            okText={"Reschedule"}
            centered
            title="Reschedule Appointment"
            open={open}
            onCancel={onClose}
            width={800}
            className="custom-modal"
            footer={null}
        >

            {isLoading ? <Loader open={isLoading} /> : ""}
            <div className='row'>
                <form onSubmit={handleReschdule} >
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className='lablesHeading'>Appointment ID</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled
                                value={info?.appointment?.Professional?.firstname ? info?.appointment?.Professional?.firstname : "NA"}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className='lablesHeading'>Professional</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled
                                value={info?.docname ? info?.docname : "NA"}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className='lablesHeading'>Customer</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled
                                value={info?.custname}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className='lablesHeading'>Status</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled
                                value={info?.status}
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label className='lablesHeading'>Current slot</label>
                            <input
                                type="text"
                                className="form-control"
                                id="time"
                                placeholder="Select Time"
                                value={time}
                                disabled
                                // onChange={(e) => { setTime(e.target.value) }}
                                required
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className='lablesHeading'>Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="date"
                                placeholder="Select Date"
                                value={date}
                                onChange={(e) => { setDate(e.target.value) }}
                                required
                            />
                        </div>

                        {slots?.length > 0 ?
                            <React.Fragment>
                                <label className='lablesHeading mt-3'>Available Slots</label>
                                <div className="time-slot-container mt-1">
                                    {slots?.map((time) => (
                                        <button
                                            type='button'
                                            key={time?.startTime}
                                            className={`time-slot ${selectedTime === time?.startTime ? 'selected' : ''}`}
                                            onClick={() => setSelectedTime(time?.startTime)}
                                        >
                                            {time?.startTime}
                                        </button>
                                    ))}
                                </div>
                            </React.Fragment> : <div className='text-center mt-3'>
                                <label className='lablesHeading mt-3'>No Slots are Available</label>
                            </div>}

                    </div>
                    <div className="mt-5 d-flex justify-content-end">
                        <button type="button" className="btn" onClick={onClose}>Cancel</button>
                        <button type="submit" className="btn btn-primary" style={{
                            cursor: slots?.length === 0 ? "not-allowed" : "pointer",
                            opacity: slots?.length === 0 ? 0.5 : 1
                        }}>Reschedule</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
export default AppointmentModal;
