import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { config } from "../utils/axiosconfig";


export async function statusChange(id, payload) {
    return axios.post(`${base_url}user/approvalOrDisapproval/${id}`, payload, config)
}
export async function viewProfessional(id) {
    return axios.post(`${base_url}user/userById/${id}`)
}