import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "../components/Content";
import { Button, Select } from "antd";
import { City, Country, State } from "country-state-city";
import { Option } from "antd/es/mentions";
import { updateProfessional } from "../features/professionals/ProfessionalService";
import { DeleteDocuments, uploadDocuments } from "../features/customers/CustomerService";
import Loader from "../components/Loader/Loader";

const ProfessionalEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location)
    const data = location.state;
    const countries = Country.getAllCountries();
    // Initialize state for each input field
    const [name, setName] = useState(data?.firstname || "");
    const [company, setCompany] = useState(data?.company || "");
    const [experience, setExperience] = useState(data?.yearsOfExperience || "");
    const [AdminCount, setAdminCount] = useState(data?.admin_rating?.count || "");
    const [AdminAvg, setAdminAvg] = useState(data?.admin_rating?.avg || "");
    const [profession, setProfession] = useState(data?.profession || "");
    const [qualification, setQualification] = useState(data?.qualification || "");
    const [speciality, setSpeciality] = useState(data?.speciality || "");
    const [prefix, setPrefix] = useState(data?.prefix || "");
    const [perDayEyeTest, setPerDayEyeTest] = useState(data?.perDayEyeTest || "");
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [slitLampAvailable, setSlitLampAvailable] = useState('');
    const userToken = JSON.parse(localStorage.getItem("user"));
    const [isLoading, setIsLoading] = useState(false);
    const [fileUpload, setFileUpload] = useState(data?.profile?.fileName);
    const handleFileInput = (e) => {
        setFileUpload(e?.target?.files[0]); // Set the selected file
    };
    const handleUpdate = async (e) => {

        e.preventDefault();
        setIsLoading(true)
        let uploadResponse = {
            fileUrl: "",
            fileName: ""
        };
    
        // Prepare payload for deleting existing documents
        const deletePayload = {
            data: [{ userId: data?._id, profile: data.profile?.fileName }] // Assuming profile.fileName has the name of the existing file
        };
    
        try {
            // Delete existing documents
            await DeleteDocuments(deletePayload);
            console.log('Existing document deleted.');
    
            // If there's a new file to upload
            if (fileUpload) {
                let formData = new FormData();
                formData.append("upload", fileUpload);
    
                // Upload the new document
                const response = await uploadDocuments(formData);
                uploadResponse = response.data[0]; // Assuming response structure
                console.log('New document uploaded:', uploadResponse);
            }
        } catch (error) {
            console.error('Error during document operations:', error);
        }
        const updatedData = {
            firstname: name,
            company: company,
            yearsOfExperience: experience,
            profession: profession,
            qualification: qualification,
            speciality: speciality,
            prefix: prefix,
            perDayEyeTest: perDayEyeTest,
            state: selectedState,
            city: selectedCity,
            slitLampAvailable:slitLampAvailable,
            userId: data._id,
            profile:uploadResponse
        };
        console.log(updatedData);
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };

        try {
            const res = await updateProfessional(updatedData, config);
            console.log('Updated Data:', res);

        } catch (err) {
            console.error(err);
        } finally {
            // onClose();
            // fetchData();
            navigate("/admin/professionals");
            setIsLoading(false);
        }
        // Here you can add your API call for updating the professional data
        // Example: api.updateProfessional(updatedData).then(...)

    };

    useEffect(() => {
        if (data) {

            setSelectedCountry(data.country || '');
            setSelectedState(data.state || '');
            setSelectedCity(data.city || '');

            if (data.country) {
                fetchStates(data.country);
                if (data.state) {
                    fetchCities(data.state, data.country);
                }
            }
        }
    }, [data]);

    const fetchStates = (countryName) => {
        const country = countries.find(item => item.name === countryName);
        if (country) {
            const stateList = State.getStatesOfCountry(country.isoCode);
            setStates(stateList);
            setCities([]); // Reset cities
        }
    };

    const fetchCities = (stateName, countryName) => {
        const state = states.find(item => item.name === stateName);
        if (state) {
            const country = countries.find(item => item.name === countryName);
            const cityList = City.getCitiesOfState(country.isoCode, state.isoCode);
            setCities(cityList);
        }
    };

    const handleCountryChange = (value) => {
        setSelectedCountry(value);
        fetchStates(value); // Fetch states based on selected country
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
        setSelectedCity(''); // Reset city when state changes
        fetchCities(value, selectedCountry); // Fetch cities based on selected state and country
    };
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };

    return (
        <Content>
            <div className='row'>
            {isLoading ? <Loader open={isLoading} /> : ""}
                <form onSubmit={handleUpdate}>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="name" className='lablesHeading'>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="prefix" className='lablesHeading'>Prefix:</label>
                            <select
                                className="form-control"
                                id="prefix"
                                value={prefix}
                                onChange={(e) => setPrefix(e.target.value)} // Update the state directly
                                placeholder="Select Prefix"
                                required
                            >
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                {/* Add more options as needed */}
                            </select>
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="company" className='lablesHeading'>Company:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="company"
                                placeholder="Enter Company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="experience" className='lablesHeading'>Years of Experience:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="experience"
                                placeholder="Enter Experience"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="experience" className='lablesHeading'>Admin Rating Count:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="experience"
                                placeholder="Enter Experience"
                                value={AdminCount}
                                onChange={(e) => setAdminCount(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="experience" className='lablesHeading'>Admin Rating Average:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="experience"
                                placeholder="Enter Experience"
                                value={AdminAvg}
                                onChange={(e) => setAdminAvg(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="profession" className='lablesHeading'>Profession:</label>
                            <select
                                className="form-control"
                                id="profession"
                                value={profession}
                                onChange={(e) => setProfession(e.target.value)}
                                required
                            >
                                <option value="">Select Profession</option>
                                <option value="Optometrist">Optometrist</option>
                                <option value="Ophthalmologist">Ophthalmologist</option>
                                {/* Add more options as needed */}
                            </select>
                        </div>

                        <div className="col-md-6 form-group mb-2">
            <label htmlFor="qualification" className='lablesHeading'>Qualification:</label>
            <select
                className="form-control"
                id="qualification"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)} // Directly set the selected value
                placeholder="Select Qualification"
                required
            >
                <option value="Doctorate">Doctorate</option>
                <option value="Master's">Master's</option>
                <option value="Graduate">Graduate</option>
                <option value="Diploma">Diploma</option>
                {/* Add more options as needed */}
            </select>
        </div>
        <div className="col-md-6 form-group mb-2">
            <label htmlFor="speciality" className='lablesHeading'>Speciality:</label>
            <select
                className="form-control"
                id="speciality"
                value={speciality}
                onChange={(e) => setSpeciality(e.target.value)} // Directly set the selected value
                placeholder="Select Speciality"
                required
            >
                <option value="Refraction">Refraction</option>
                <option value="Paediatrics">Paediatrics</option>
                <option value="Medicines">Medicines</option>
                <option value="Surgery">Surgery</option>
                {/* Add more options as needed */}
            </select>
        </div>

                        <div className="col-md-6 form-group mb-2">
                            <label htmlFor="perDayEyeTest" className='lablesHeading'>Per Day Eye Test:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="perDayEyeTest"
                                placeholder="Enter Per Day Eye Test"
                                value={perDayEyeTest}
                                onChange={(e) => setPerDayEyeTest(e.target.value)}
                                required
                            />
                        </div>
                <div className="col-md-6">
                <label className="form-check-label lablesHeading" htmlFor="flexRadioDefaultYes">
                slitLampAvailable
        </label>
   <div className="d-flex gap-5">
   <div className="form-check">
        <input 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="flexRadioDefaultYes" 
          value="Yes" 
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefaultYes">
          Yes
        </label>
      </div>
      <div className="form-check">
        <input 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="flexRadioDefaultNo" 
          value="No" 
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefaultNo">
          No
        </label>
      </div>
   </div>
      <div>
        {/* <p>Selected Option: {selectedOption || "None selected"}</p> */}
      </div>
                </div>
                        <div className="col-md-6 form-group mb-2">
                            <label className='labelsHeading'>State</label>
                            <Select
                                placeholder="Select a state"
                                onChange={handleStateChange}
                                value={selectedState}
                                style={{ width: '100%' }}
                                disabled={!selectedCountry}
                            >
                                {states.map(state => (
                                    <Option key={state.isoCode} value={state.name}>{state.name}</Option>
                                ))}
                            </Select>
                        </div>

                        <div className="col-md-6 form-group mb-2">
                            <label className='labelsHeading'>City</label>
                            <Select
                                placeholder="Select city"
                                value={selectedCity}
                                style={{ width: '100%' }}
                                onChange={setSelectedCity}
                                disabled={!selectedState}
                            >
                                {cities.map(city => (
                                    <Option key={city.id} value={city.name}>{city.name}</Option>
                                ))}
                            </Select>
                        </div>
        
                        <div className="col-md-6 form-group">
                        <label className='labelsHeading'>Profile Img</label>
                        <input
                            type="file"
                            className="form-control"
                            // value={fileUpload}
                            onChange={handleFileInput}
                            required
                        />
<span>{fileUpload?.name || "No file selected"}</span>                    </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-3">
                        <Button onClick={() => navigate("/admin/professionals")}>Cancel</Button>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            </div>
        </Content>
    );
};

export default ProfessionalEdit;
