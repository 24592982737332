import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoMdClose } from "react-icons/io";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

export default function LocationViewModal({ open, toggle, location }) {
    return (
        <>
            <Modal isOpen={open} toggle={toggle} size='lg' centered>
                <ModalBody>
                    <div className='d-flex justify-content-end'>
                        <IoMdClose className='pointer fs-5' onClick={toggle} />
                    </div>
                    <div>
                        {open?<Map latitude={location?.lat !== null?+(location?.lat):location?.lat} longitude={location?.lng !== null?+(location?.lng):location?.lng} />:''}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}


function Map({ latitude, longitude }) {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCKodpnQoon5aC5csg7IXgFKD2gETOl0HA',
    });

    const containerStyle = {
        width: '100%',
        height: '500px'
    };


    const center = {
        lat: latitude,
        lng: longitude,
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
        >
            <Marker position={center} />
        </GoogleMap>
    )
}