// InvoiceModal.js
import React, { useRef } from "react";

import { useLocation, useParams } from "react-router-dom";
import Content from "../components/Content";
import { Table } from "reactstrap";
import moment from "moment";

const ProfessionalView = () => {
  const {id} = useParams()
  const location = useLocation();
  const data = location.state?.professional;;
  console.log(data);
  let verification = "";
  if (data?.is_verified) {
    verification = "Approved";
  } else {
    verification = "Pending";
  }

  async function getDataById(){
    try{

    }catch(err){
      console.log(err)
    }
  }





  const TableRow = ({ label, value, color }) => (
    <tr>
      <td className="text-nowrap lablesHeading" style={{ width: '50%' }}>{label}</td>
      <td className="text-nowrap" style={{ width: '50%', color: color }}>{value}</td>
    </tr>
  );
  const date = moment(data?.appointmentDateTime).utc().format("DD/MM/YYYY");
  const time = moment(data?.appointmentDateTime).utc().format("hh:mm A");
  return (
    <Content>

      <div className="text-center d-flex gap-3 align-items-center mb-3">
        <img
          src={data?.profile.fileUrl ? data.profile.fileUrl : 'logo.png'} // Replace with your default logo path
          alt="Profile"
          className="profile-logo" onError={(e) => { e.target.src = 'logo.png'; }} // Replace with your default logo path
        />
        <span className="mb-2 sub-title">Professional</span>
      </div>
      <div className='row'>

        <div className="col-md-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Select Date"
            disabled
            value={data?.firstname ? data?.firstname : "NA"}
          />
        </div>
        <div className="col-md-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Email</label>
          <input
            type="text"
            className="form-control"
            disabled
            value={data?.email ? data?.email : "NA"}

          />
        </div>
        <div className="col-md-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Mobile</label>
          <input
            type="text"
            className="form-control"
            disabled
            value={data?.mobile ? data?.mobile : "NA"}
          />
        </div>
        <div className="col-md-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Select Date"
            value={[data?.city, data?.state, data?.country,].join(",")}
            disabled
          />
        </div>
        <div className="col-md-3 form-group mt-3">
          <label htmlFor="date" className='lablesHeading'>pinCode</label>
          <input
            type="text"
            className="form-control"
            placeholder="Select Date"
            value={data?.pinCode}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Qualification</label>
          <input
            type="text"
            className="form-control"
            value={data?.qualification ? data?.qualification : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>PerDay Eye Test</label>
          <input
            type="text"
            className="form-control"
            value={data?.perDayEyeTest ? data?.perDayEyeTest : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Profession</label>
          <input
            type="text"
            className="form-control"
            value={data?.profession ? data?.profession : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Speciality</label>
          <input
            type="text"
            className="form-control"
            value={data?.speciality ? data?.speciality : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Experience</label>
          <input
            type="text"
            className="form-control"

            value={data?.yearsOfExperience ? data?.yearsOfExperience : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Verification</label>
          <input
            type="text"
            className="form-control"
            value={verification ? verification : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Company</label>
          <input
            type="text"
            className="form-control"
            value={data?.company ? data?.company : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Prefix</label>
          <input
            type="text"
            className="form-control"
            value={data?.prefix ? data?.prefix : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Total Ratings</label>
          <input
            type="text"
            className="form-control"
            value={data?.totalRatings ? data?.totalRatings : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Average Ratings</label>
          <input
            type="text"
            className="form-control"
            value={data?.averageRating ? data?.averageRating : "NA"}
            disabled
          />
        </div>
        <div className="col-md-3 mt-3 form-group">
          <label htmlFor="date" className='lablesHeading'>Slit Lamp Available</label>
          <input
            type="text"
            className="form-control"
            value={data?.slitLampAvailable ? "Yes" : "No"}
            disabled
          />
        </div>
      </div>

      <div className="row">

        {data?.availability && (
          <div className="col-md-12 mt-4">
            <h6 className="sub-title mb-3">Working Hours</h6>
            <table className="table">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
              </thead>
              <tbody>
                {data?.availability?.timeSlots?.map((timeSlot, timeSlotIndex) =>
                  timeSlot?.slots?.map((slot, slotIndex) => (
                    <tr key={`${timeSlotIndex}-${slotIndex}`}>
                      <td>{timeSlot?.dayOfWeek}</td>
                      <td>{slot?.startTime}</td>
                      <td>{slot?.endTime}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

          </div>
        )}

      </div>
    </Content>
  );
};

export default ProfessionalView;
