// InvoiceModal.js
import React, { useRef, useState } from "react";
import { Modal } from "antd";
import { Table } from "reactstrap";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";
import { updaterating } from "../features/professionals/ProfessionalService";
import Loader from "../components/Loader/Loader";

const RatingModel = ({ open, onClose, data }) => {
  const userToken = JSON.parse(localStorage.getItem("user"));
  console.log(data)
  const [isLoading, setIsLoading] = useState(false);
  const [average, setAverage] = useState("");
  const [count, setCount] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    let payload = {
      count: count,
      avg: average,
      use_admin_rating: data.professional.use_admin_rating,
      id: data.professional._id
    }
    console.log(payload)
    const config = {
      headers: {
          Authorization: `Bearer ${userToken?.token}`
      }
  };
    try {
      let res = await updaterating(payload,config)
      console.log(res)
    } catch (err) {
      console.log(err)
    }
    onClose()
  };
  return (
    <React.Fragment>

      <Modal
        okText={"Submit"}
        centered
        title="Rating"
        open={open}
        onCancel={onClose}
        width={800}
        className="custom-modal"
      onOk={handleSubmit}
      >
        <div className='row'>
        {isLoading ? <Loader open={isLoading} /> : ""}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="average">Average:</label>
                <input
                  type="number"
                  className="form-control"
                  id="average"
                  placeholder="Select Average"
                  value={average}
                  onChange={(e) => setAverage(e.target.value)}
                />
              </div>

              <div className="col-md-6 form-group">
                <label htmlFor="count">Count:</label>
                <input
                  type="number"
                  className="form-control"
                  id="count"
                  placeholder="Select Count"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />
              </div>
            </div>
          </form>

        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RatingModel;
